import React from "react"

import ButtonLink from "./ButtonLink"

// Default Call to Action is "Say Hello"

const CTA = ({
  title = "Come say hello!",
  text = "Join us for a complimentary consultation and start planning your path to better movement.",
  buttonText = "Book a free consultation",
  to = "https://artandsciencephysio.janeapp.com/#/staff_member/1/treatment/8",
}) => {
  return (
    <div className="col-span-full lg:col-start-2 col-end-12 bg-lightest-grey-blue p-8 grid gap-y-6">
      <h3 className="text-charcoal text-2xl lg:text-4xl ">{title}</h3>
      <p className="text-charcoal">{text}</p>
      <ButtonLink text={buttonText} className="justify-self-start " to={to} />
    </div>
  )
}

export default CTA
