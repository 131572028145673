import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import CTA from "../components/CTA"
import SEO from "../components/SEO"

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Grid className="items-start gap-y-4 lg:gap-y-10 text-charcoal">
        <h1 className="col-span-full lg:col-start-2 lg:mb-10">Contact Us</h1>
        <div className="col-span-full lg:col-span-4 lg:col-start-2">
          <h2 className="my-5 md:my-10 text-dark-grey-blue">Find us at</h2>
          <div className="hidden md:block mb-10">
            10 Main St. East, Smiths Falls, ON
          </div>
          <a
            href="https://goo.gl/maps/sehzqmM3rHSZztcM7"
            target="_blank"
            rel="noopener noreferrer"
            className="block md:hidden mb-10 inlineLink font-normal"
          >
            10 Main St. East, Smiths Falls, ON
          </a>
          <div className="grid gap-y-3  md:grid-cols-2 max-w-xs ">
            <div className=" w-min">Email</div>
            <a
              href="mailto:alexis@artandsciencephysio.com"
              className="inlineLink font-normal"
            >
              alexis@artandsciencephysio.com
            </a>

            <span>Fax</span>
            <div className=" font-normal">613-651-9659</div>
            <span>Phone</span>
            <a href="tel:+16132831818" className="inlineLink font-normal">
              613-283-1818
            </a>
          </div>
        </div>
        <div className="col-span-full  lg:justify-self-end lg:col-start-7 col-end-12  mt-8 ">
          <GatsbyImage
            image={getImage(data.storefrontImage)}
            alt="Art and Science Physio storefront"
            imgClassName="rounded-2xl"
          />
        </div>

        <div className=" mt-10 col-span-full md:col-start-2 col-end-12  ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2401.020610879063!2d-76.02033929302796!3d44.89922251299089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccd897ffb33319f%3A0xeec07ee9ec0926a1!2sArt%20%2B%20Science%20Physiotherapy%20and%20Pilates!5e0!3m2!1sen!2sca!4v1635370693442!5m2!1sen!2sca"
            height="450"
            className="border-0  w-full"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <CTA />
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    storefrontImage: file(relativePath: { eq: "IMG_5757-cropped.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 500)
      }
    }
    map: file(relativePath: { eq: "map.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, width: 1500)
      }
    }
  }
`

export default Contact
